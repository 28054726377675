import { Form, Select as AntSelect } from "antd";
import React, { useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";

export const Select: React.FC<{
  control: any;
  setFinalPrice?: any;
}> = ({ control, setFinalPrice }) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name: `${control.uuid}` });
  const methonds = useFormContext();
  const options = control.options.map((option: any) => {
    return {
      value: option.value,
      label: `${option.label} (+${(option.price / 100).toFixed(2)} zł)`,
    };
  });

  const value = methonds.watch(`${control.uuid}`);
  useEffect(() => {
    setFinalPrice((state: any) => {
      if (value) {
        const price = control.options.find(
          (e: any) => e.value === value
        )?.price;

        if (price !== undefined && price !== null) {
          return [
            ...state.filter((e: any) => e.id.control !== control.uuid),
            {
              id: {
                control: control.uuid,
                option: value,
              },
              value: price,
            },
          ];
        } else {
          return [...state];
        }
      } else {
        return [...state.filter((e: any) => e.id.control !== control.uuid)];
      }
    });
  }, [value]);

  if (control.field_type === "multi-select") {
    return (
      <Form.Item
        label={control.field_name}
        required={control.required}
        validateStatus={invalid ? "error" : undefined}
        help={invalid ? error?.message : null}
      >
        <AntSelect
          allowClear
          style={{
            height: "40px",
          }}
          disabled={!control.writeable}
          mode="multiple"
          options={options}
          {...field}
        />
      </Form.Item>
    );
  }

  if (control.field_type === "select") {
    return (
      <Form.Item
        label={control.field_name}
        required={control.required}
        validateStatus={invalid ? "error" : undefined}
        help={invalid ? error?.message : null}
      >
        <AntSelect
          allowClear
          style={{
            height: "40px",
          }}
          disabled={!control.writeable}
          options={options}
          {...field}
        />
      </Form.Item>
    );
  }

  return null;
};
