import React from "react";
import { CategoriesSlider } from "../../molecules/categories-slider/CategoriesSlider";
import styled from "styled-components";
import { SingleMeal } from "../../molecules/single-meal/SingleMeal";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import { useQuery } from "react-query";
import { getServices } from "../../../service";
import { motion } from "framer-motion";
import useScrollFromTop from "../../../hooks/useScrollFromTop";
import { useAppContext } from "../../../contexts/useAppContext";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { ServicesCategoriesSlider } from "../../molecules/services-categories-slider/ServicesCategoriesSlider";
import { useNavigate } from "react-router-dom";

const MealsWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`;

export const ServicesPage: React.FC = () => {
  useScrollToTop();
  const navigate = useNavigate();

  const { scrollTop } = useScrollFromTop();
  const { cart, setCart, selectedCategory } = useAppContext();

  const { data: services = [], isFetching } = useQuery(
    ["/services", selectedCategory],
    () => getServices(selectedCategory?.id),
    { enabled: !!selectedCategory }
  );

  const animations = {
    initial: { opacity: 0, x: "10vw" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0 },
  };

  const onClickChandler = (meal: any) => {
    navigate(`/reservate/${meal.id}`, { state: { from: "home" } });

    setCart([
      {
        cart_positoin: {
          meal_quantity: 1,
          meal_name: meal.name,
          meal_options: [],
          meal_price: meal.price,
          id: meal.id,
        }
      },
    ]);
  };
  return (
    <>
      <ServicesCategoriesSlider />
      {!isFetching && services ? (
        <AnimadetPage>
          <div
            style={{
              paddingBottom: cart.length > 0 ? "100px" : "0px",
            }}
          >
            <motion.div
              variants={animations}
              initial="initial"
              animate="animate"
              exit="exit"
              style={{
                paddingTop: scrollTop > 64 ? "35px" : "0px",
              }}
            >
              <>
                <P
                  $size="18"
                  $height="24"
                  $weight="600"
                  style={{
                    paddingLeft: "12px",
                    paddingTop: "24px",
                    paddingBottom: "5px",
                  }}
                  id={selectedCategory?.id}
                >
                  {selectedCategory?.name}
                </P>
                <P
                  $size="14"
                  $weight="300"
                  $height="20"
                  $family="Lato-Light"
                  style={{
                    paddingLeft: "12px",
                    paddingTop: "5px",
                    paddingBottom: "26px",
                  }}
                  id={selectedCategory?.id}
                >
                  {selectedCategory?.descripotion}
                </P>
                <MealsWrapper>
                  {services?.map((service: any) => {
                    return (
                      <SingleMeal
                        key={service.id}
                        meal={service}
                        onClick={onClickChandler}
                      />
                    );
                  })}
                </MealsWrapper>
              </>
            </motion.div>
          </div>
        </AnimadetPage>
      ) : (
        <LoadingScreen />
      )}
      {/* {cart.length > 0 && <GoToCartButton />} */}
    </>
  );
};
