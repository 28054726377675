/* eslint-disable no-restricted-globals */

import { Layout } from "antd";
import { AppContent } from "./components/templates/app-content/AppContent";
import { Header } from "./components/organisms/header/Header";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "./contexts/useAppContext";

const { Content } = Layout;

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedBranch } = useAppContext();
  const [showPage, setShowPage] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/" && !selectedBranch) {
      navigate("/");
    } else {
      setShowPage(true);
    }
  }, [navigate, location]);
  console.log("hostname", window.location);
  if (showPage)
    return (
      <Layout>
        <Header />

        <Content
          style={{
            background: "#f9fafb",
          }}
        >
          <AppContent />
        </Content>

        {/* <Footer>Footer</Footer> */}
      </Layout>
    );

  return null;
}
export default App;
