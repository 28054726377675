import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import { useAppContext } from "../../../contexts/useAppContext";
import { CartPosition } from "../../molecules/cart-position/CartPosition";
import { GoToDelivery } from "../../molecules/go-to-delivery/GoToDelivery";
import { useNavigate } from "react-router-dom";
import useScrollToTop from "../../../hooks/useScrollToTop";

const MealsWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 142px;
  min-height: calc(100vh + 142px);
`;

export const CartPage: React.FC = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const { cart } = useAppContext();

  useEffect(() => {
    if (cart.length === 0) {
      navigate("/", { state: { from: "home" } });
    }
  }, [cart]);

  return (
    <>
      <AnimadetPage>
        <MealsWrapper>
          {cart.map((element: any) => {
            return <CartPosition meal={element} />;
          })}
        </MealsWrapper>
      </AnimadetPage>
      <GoToDelivery />
    </>
  );
};
