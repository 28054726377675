import React from "react";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import styled from "styled-components";

import { useQuery } from "react-query";
import { getDetails } from "../../../service";
import { useParams } from "react-router-dom";

import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { SetDataAction } from "../../molecules/set-data-action/SetDataAction";
import { DataPickerCarusel } from "../../atoms/data-picker-carusel/DataPickerCarusel";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { TimePickerList } from "../../atoms/time-picker-list/TimePickerList";
import { useAppContext } from "../../../contexts/useAppContext";

const DetailsWrapper = styled.div`
  padding: 16px;
  background: white;
  min-height: calc(100vh);
`;

export const DataPicker: React.FC = () => {
  useScrollToTop();
  const { serviceData } = useAppContext();

  return (
    <>
      <AnimadetPage>
        <DetailsWrapper>
          <P
            $size="12"
            $height="16"
            $weight="400"
            style={{ marginBottom: "10px" }}
          >
            Data
          </P>
          <DataPickerCarusel />
          {serviceData && (
            <>
              <P
                $size="12"
                $height="16"
                $weight="400"
                style={{ marginBottom: "10px" }}
              >
                Godzina
              </P>
              <TimePickerList />
            </>
          )}
        </DetailsWrapper>
      </AnimadetPage>
      <SetDataAction />
    </>
  );
};
