import React from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";

const GoToDeliveryButtonWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: white;
  z-index: 1000;
`;

export const BranchHeader: React.FC = () => {
  return (
    <GoToDeliveryButtonWrapper>
      <img
        src="https://pertuttidt.pl/img/logo.svg"
        width={70}
        style={{
          marginLeft: "calc(50% - 35px)",
        }}
      />
      <P
        $size="20"
        $height="28"
        $weight="600"
        $family="Lato"
        style={{
          marginTop: "15px",
          marginBottom: "25px",
          textAlign: "center",
        }}
      >
        Witamy w PerTutti
      </P>
      <P
        $size="18"
        $height="24"
        $weight="600"
        $family="Lato"
        style={{
          marginBottom: "5px",
        }}
      >
        Wybierz lokal w którym chcesz złożyć zamówienie
      </P>
    </GoToDeliveryButtonWrapper>
  );
};
