import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import { Toggle } from "../../atoms/toggle/Toggle";
import {
  Avatar,
  Button,
  ConfigProvider,
  DatePicker,
  Flex,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
} from "antd";
import User from "../../../images/User.svg";
import Package from "../../../images/Package.svg";
import Clock2 from "../../../images/Clock2.svg";
import Receipt from "../../../images/Receipt.svg";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useQuery } from "react-query";
import { getCompanyPayments, getDelivery } from "../../../service";
import {
  createFormInitialValue,
  createShema,
  joiResolver,
} from "../../../utils";
import { FormProvider, useForm } from "react-hook-form";
import { SupportedControls } from "../../molecules/supported-controls/SupportedControls";
import { motion } from "framer-motion";
import plPL from "antd/es/locale/pl_PL";
import locale from "antd/es/date-picker/locale/pl_PL";
import { useAppContext } from "../../../contexts/useAppContext";
import { AdditionPrice } from "../../atoms/addition/Addition";
import { GoToPay } from "../../molecules/go-to-pay/GoToPay";
import { GoToSummary } from "../../molecules/go-to-summary/GoToSummary";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { useLocation } from "react-router-dom";

const MealsWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 142px;
  min-height: calc(100vh + 142px);
  background: #f9fafb;
`;

const Meal = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 24px;
  margin-bottom: 10px;
  background: white;
  box-shadow: 1px 1px 1px 1px #edebfb;
  padding: 16px;
`;

const CustomSearchButton = styled(Input.Search)`
  margin-bottom: 25px;
  .ant-input {
    border-color: #4f46e5;
  }
  .ant-input-search-button {
    background: #4f46e5;
  }

  .ant-input:hover {
    border-color: #4f46e5 !important;
  }
  .ant-input-search-button:hover {
    background: #4f46e5 !important;
    border-color: #4f46e5 !important;
  }
`;
const CustomRadio = styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #4f46e5;
    background-color: #4f46e5;
  }
`;

export const SummaryPage: React.FC = () => {
  useScrollToTop();
  const { cart, deliveryPrice, deliveryData } = useAppContext();
  const location = useLocation();
  const isOrder = !!location?.state?.isOrder;

  // const [value, setValue] = useState("ONLINE_PAY");

  // const onChange = (e: RadioChangeEvent) => {
  //   console.log("radio checked", e.target.value);
  //   setValue(e.target.value);
  // };

  const total_ammount = useMemo(
    () =>
      cart
        .map(
          (position: any) =>
            position.cart_positoin.meal_price *
            position.cart_positoin.meal_quantity
        )
        .reduce((accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        }, 0),
    [cart]
  );
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState<any>();

  const selectedOptionObject = options.find(
    (e: any) => e.value === selectedOptions
  );

  const { isFetching } = useQuery(["/payment_methods"], getCompanyPayments, {
    onSuccess(data) {
      const cretedOption = data.map((e: any) => {
        return { value: e.id, label: e.name };
      });
      cretedOption?.[0]?.value && setSelectedOptions(cretedOption?.[0]?.value);
      setOptions(cretedOption);
    },
  });

  return (
    <>
      <AnimadetPage>
        <MealsWrapper>
          <Meal>
            <Space
              style={{
                marginBottom: "25px",
              }}
            >
              <Avatar
                shape="square"
                style={{
                  background: "#eef2ff",
                }}
                src={Receipt}
              />
              <P $size="16" $weight="400" $height="20">
                {isOrder ? "Podsumowanie zamówienia" : "Rezerwujesz"}
              </P>
            </Space>

            {cart.map((element: any) => {
              console.log("element cart", element);
              return (
                <Flex
                  vertical
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <Space>
                    <P $size="16" $height="20" $weight="400">
                      {element.cart_positoin.meal_name}
                    </P>
                  </Space>

                  {element.cart_positoin.meal_options.map(
                    ({ controls }: any) => {
                      return controls.map((control: any) => {
                        return (
                          <>
                            <Space
                              style={{
                                columnGap: "4px",
                              }}
                            >
                              <span>
                                <P
                                  $size="14"
                                  $height="16"
                                  $weight="400"
                                  $family="Lato-Light"
                                >
                                  {control.name}:
                                </P>
                              </span>
                              <span>
                                <P
                                  $size="14"
                                  $height="20"
                                  $weight="300"
                                  $family="Lato-Light"
                                >
                                  {control.value}
                                </P>
                              </span>
                            </Space>
                          </>
                        );
                      });
                    }
                  )}
                </Flex>
              );
            })}
          </Meal>
          {deliveryData.frontend_data.map(({ sectionName, controls }: any) => {
            return (
              <Meal>
                <Space
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <Avatar
                    shape="square"
                    style={{
                      background: "#eef2ff",
                    }}
                    src={Receipt}
                  />
                  <P $size="16" $weight="400" $height="20">
                    {sectionName}
                  </P>
                </Space>

                <Flex
                  vertical
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  {controls.map((control: any) => {
                    return (
                      <Space
                        style={{
                          columnGap: "4px",
                        }}
                      >
                        <span>
                          <P
                            $size="14"
                            $height="16"
                            $weight="400"
                            $family="Lato"
                          >
                            {control.name}:
                          </P>
                        </span>
                        <span>
                          <P
                            $size="14"
                            $height="20"
                            $weight="300"
                            $family="Lato-Light"
                          >
                            {control.value}
                          </P>
                        </span>
                      </Space>
                    );
                  })}
                </Flex>
              </Meal>
            );
          })}

          <Meal>
            <Space
              style={{
                marginBottom: "25px",
              }}
            >
              <Avatar
                shape="square"
                style={{
                  background: "#eef2ff",
                }}
                src={Receipt}
              />
              <P $size="16" $weight="400" $height="20">
                Sposób zapłaty
              </P>
            </Space>
            <Flex justify="center" align="center">
              <Select
                style={{
                  width: "100%",
                  height: "35px",
                  marginBottom: "10px",
                }}
                options={options}
                loading={isFetching}
                onChange={setSelectedOptions}
                value={selectedOptions}
              />
            </Flex>
            {/* <Flex justify="center" align="center">
              <CustomSearchButton
                placeholder="Kod rabatowy"
                enterButton="Zastosuj"
                size="large"
              />
            </Flex> */}
            <Flex
              justify="space-between"
              align="center"
              style={{
                marginBottom: "5px",
              }}
            >
              <P $size="16" $height="20" $weight="400" $family="Lato-Light">
                Podsumowanie
              </P>
              <P $size="16" $height="20" $weight="400" $family="Lato-Light">
                <AdditionPrice price={total_ammount} />
              </P>
            </Flex>
            {isOrder && (
              <Flex
                justify="space-between"
                align="center"
                style={{
                  marginBottom: "5px",
                }}
              >
                <P $size="16" $height="20" $weight="400" $family="Lato-Light">
                  Dostawa
                </P>
                <P $size="16" $height="20" $weight="400" $family="Lato-Light">
                  <AdditionPrice price={deliveryPrice} />
                </P>
              </Flex>
            )}
            <Flex justify="space-between" align="center">
              <P $size="18" $height="26" $weight="600">
                Do zapłaty
              </P>
              <P $size="18" $height="26" $weight="600">
                <AdditionPrice price={total_ammount + deliveryPrice} />
              </P>
            </Flex>
          </Meal>
        </MealsWrapper>
      </AnimadetPage>
      <GoToPay selectedOptionObject={selectedOptionObject} />
    </>
  );
};
