import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { getDeliveryPrice, getPlace, getSuggestions } from "../../../service";
import { useAppContext } from "../../../contexts/useAppContext";

const { Option } = Select;

export const GoogleMapsAPI: React.FC<{ control: any }> = ({ control }) => {
  const {
    fieldState: { invalid, error: inputError },
  } = useController({ name: `${control.uuid}` });
  const methods = useFormContext();

  const actualValue = methods.watch(control.uuid);
  const { setDeliveryPrice } = useAppContext();
  const [timer, setTimer] = useState(setTimeout(() => {}, 1000));
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { mutateAsync, isLoading } = useMutation(getSuggestions, {
    onSuccess: async (data) => {
      if (
        data.predictions &&
        Array.isArray(data.predictions) &&
        data.status === "OK"
      ) {
        const options = data.predictions
          .filter((location: any) => location?.types?.includes("premise"))
          .map((location: any) => {
            return {
              label: location.description,
              value: location.place_id,
            };
          });

        if (options.length > 0) {
          setSuggestions(options);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    },
  });

  const [deliveryPriceError, setDeliveryPriceError] = useState(false);
  const { mutateAsync: getPriceMutateAsync, isLoading: getPriceLoading } =
    useMutation(getDeliveryPrice, {
      onSuccess: async (data) => {
        if (data.status === "OK") {
          setDeliveryPrice(data.price);
        } else {
          setDeliveryPriceError(true);
        }
      },
    });

  const { mutateAsync: getPlaceMutateAsync, isLoading: getPlaceLoading } =
    useMutation(getPlace, {
      onSuccess: async (data, request) => {
        if ((data.status === "OK", data?.result?.formatted_address)) {
          methods.setValue(control.uuid, {
            place_id: request.place_id,
            name: data?.result?.formatted_address,
          });
        }
      },
    });

  const addressOnChangeHandler = (value: any) => {
    if (value === "") {
      setSuggestions([]);
    }

    setLoading(true);
    setError(false);
    setDeliveryPriceError(false);
    clearTimeout(timer);

    setTimer(
      setTimeout(() => {
        mutateAsync({ input: value });
        setLoading(false);
      }, 1100)
    );
  };

  useEffect(() => {
    if (localStorage.getItem(control.uuid)) {
      const place_id =
        JSON.parse(localStorage.getItem(control.uuid) ?? "{}") ?? null;

      if (place_id) {
        getPriceMutateAsync({ place_id: place_id });
        getPlaceMutateAsync({
          place_id: place_id,
        });
      }
    }

    return () => {
      methods.unregister(`${control.uuid}`);
    };
  }, []);

  return (
    <>
      <Form.Item
        label="Adres (Miasto, ulica, numer budynku)"
        required={control.required}
        validateStatus={
          error || deliveryPriceError || invalid || inputError
            ? "error"
            : undefined
        }
        help={
          error
            ? "Nie znaleziono adresu"
            : deliveryPriceError
            ? "Nie dostarczamy pod ten adres :("
            : invalid || inputError
            ? "Pole jest wymagane"
            : undefined
        }
      >
        <Select
          loading={isLoading || loading || getPriceLoading || getPlaceLoading}
          style={{
            height: "40px",
          }}
          showSearch
          placeholder="Wpisz adres dostawy"
          onSearch={addressOnChangeHandler}
          filterOption={false}
          notFoundContent={null}
          value={actualValue?.name}
          onSelect={(_value, label) => {
            getPriceMutateAsync({ place_id: label.key });
            methods.setValue(control.uuid, {
              place_id: label.key,
              name: label.children,
            });
          }}
        >
          {suggestions.map((option: any) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};
