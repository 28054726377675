import { LeftOutlined, MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { Avatar, Button, Drawer, Flex, Space } from "antd";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useQueryClient } from "react-query";
import { useAppContext } from "../../../contexts/useAppContext";
import styled from "styled-components";
import CaretLeft from "../../../images/CaretLeft.svg";
const headerStyle: React.CSSProperties = {
  height: "100%",
  paddingLeft: "8px",
  paddingRight: "8px",
};

const CostumButton = styled(Button)`
  max-width: 35px !important;
  width: 35px !important;
  min-width: 35px !important;
  max-height: 35px !important;
  height: 35px !important;
  background: #f5f5f5;
`;

export const Header: React.FC = () => {
  const { selectedBranch } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showFirstDiv, setShowFirstDiv] = useState(true);
  const { mealName } = useAppContext();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/menu") {
      setIsCollapsed(true);
    } else {
      setTimeout(() => {
        setIsCollapsed(false);
        setShowFirstDiv(true);
      }, 300);

      setIsCollapsed(false);
    }
  }, [location]);

  if (location.pathname !== "/" && location.pathname !== "/statute") {
    return (
      <motion.div
        initial={{ height: "64px" }}
        animate={{ height: isCollapsed ? "44px" : "64px" }}
        transition={{ duration: 0.3 }}
      >
        <Flex
          style={headerStyle}
          align="center"
          justify="space-between"
          gap={showFirstDiv ? 8 : 0}
        >
          <Flex
            style={headerStyle}
            align="center"
            justify="space-between"
            gap={showFirstDiv ? 8 : 0}
          >
            {!showFirstDiv && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: isCollapsed && !showFirstDiv ? 1 : 0 }}
                transition={{ duration: 0.3, delay: 0 }}
              >
                <Button
                  type="text"
                  onClick={() => navigate(-1)}
                  style={{
                    paddingTop: "6px",
                  }}
                  icon={<img src={CaretLeft} />}
                ></Button>
                
              </motion.div>
            )}
            {showFirstDiv && (
              <motion.div
                initial={{ opacity: 1, x: 0, y: 0 }}
                animate={{
                  opacity: isCollapsed ? 0 : 1,
                  x: isCollapsed ? "-10px" : 0,
                  y: isCollapsed ? "-5px" : 0,
                }}
                transition={{ duration: 0.3 }}
                onAnimationComplete={() => {
                  setShowFirstDiv(!isCollapsed);
                }}
                style={{
                  width: "100%",
                }}
              >
                <P $size="14" $height="16" $weight="400">
                  {selectedBranch && selectedBranch.name}
                </P>
                <P $size="12" $height="20" $weight="300" $family="Lato-Light">
                  {selectedBranch && selectedBranch.description}
                </P>
              </motion.div>
            )}
          </Flex>
          <CostumButton
            onClick={() => {
              setOpen(true);
            }}
            style={{
              borderColor: "#4F46E5",
            }}
            shape="circle"
            icon={
              <MenuOutlined
                style={{
                  color: "#4F46E5",
                }}
              />
            }
          />
        </Flex>
        <Drawer onClose={() => setOpen(false)} open={open}>
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Drawer>
      </motion.div>
    );
  }

  return null;
};
