import { Form, Checkbox as AntCheckbox, Button, Flex } from "antd";
import React, { useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useAppContext } from "../../../contexts/useAppContext";
import { P } from "../paragraph/Paragraph.styled";
import { useNavigate } from "react-router-dom";

export const ChangeReservationDate: React.FC<{
  control: any;
  setFinalPrice?: any;
}> = ({ control, setFinalPrice }) => {
  const methonds = useFormContext();

  const navigate = useNavigate();
  const { serviceData, serviceTime, setServiceData, setServiceTime } =
    useAppContext();

  useEffect(() => {
    if (serviceData && serviceTime) {
      methonds.setValue(control.uuid, `${serviceData} ${serviceTime}`);
    }
  }, [serviceData, serviceTime]);

  return (
    <Form.Item label={control.field_name} required={control.required}>
      <Flex
        justify="space-between"
        style={{
          paddingTop: "7px",
        }}
      >
        <P $size="14" $weight="300" $height="20" $family="Lato-Light">
          {`${serviceData} ${serviceTime}`}
        </P>
        <Button
          onClick={() => {
            navigate(-1);
            setServiceData(null);
            setServiceTime(null);
          }}
          type="link"
          style={{
            paddingRight: 0,
            color: "#4F46E5",
          }}
        >
          <P $size="16" $height="20" $weight="400">
            Zmień termin
          </P>
        </Button>
      </Flex>
    </Form.Item>
  );
};
