import React from "react";
import { P } from "../paragraph/Paragraph.styled";
import styled from "styled-components";

const AdditionsHeaderWrapper = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  background: #f9fafb;
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: uppercase;
`;

export const AdditionsHeader: React.FC<{ text: any }> = ({ text }) => {
  return (
    <AdditionsHeaderWrapper>
      <P $height="14" $size="18" $weight="600">
        {text}
      </P>
    </AdditionsHeaderWrapper>
  );
};
