import { Avatar, Flex, Space } from "antd";
import React from "react";

import styled from "styled-components";
import { Labels } from "../labels/Labels";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import test from "../../../images/meal.jpg";

const Meal = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 24px;
  margin-bottom: 10px;
  background: white;
  box-shadow: 1px 1px 1px 1px #edebfb;
  padding: 16px;
  cursor: pointer;
`;

export const SingleMeal: React.FC<{ meal: any; onClick?: any }> = ({
  meal,
  onClick,
}) => {
  const price = meal.price ?? meal.basePrice;
  return (
    <Meal
      onClick={() => {
        onClick && onClick(meal);
      }}
    >
      <Flex
        justify="space-between"
        style={{
          marginBottom: "15px",
        }}
      >
        <Flex vertical>
          <P $size="18" $height="24" $weight="400">
            {meal.name}
          </P>
          <P $size="14" $height="20" $weight="300" $family="Lato-Light">
            {meal.descripotion}
          </P>
        </Flex>
        <Avatar
          style={{
            minWidth: "110px",
          }}
          size={110}
          shape="square"
          src={test}
        />
      </Flex>
      <Flex justify="space-between">
        <Space>
          <P
            $size="18"
            $height="24"
            $weight="400"
            style={{
              color: "#4f46e5",
              margin: "0px",
              whiteSpace: "nowrap",
            }}
          >
            {(price/ 100).toFixed(2)} zł
          </P>
        </Space>
        <Labels />
      </Flex>
    </Meal>
  );
};
