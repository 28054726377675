import { LoadingOutlined } from "@ant-design/icons";
import { Spin as AntSpin } from "antd";
import React from "react";
import styled from "styled-components";

const LoadingScreenWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
`;
const Spin = styled(AntSpin)`
  background: white;
  .anticon {
    font-size: 70px !important;
  }
`;

export const LoadingScreen: React.FC = () => {
  return (
    <LoadingScreenWrapper>
      <Spin
        fullscreen
        size="large"
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      />
    </LoadingScreenWrapper>
  );
};
