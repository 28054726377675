export const createMealOptionFrontView = (sections: any, data: any) => {
  const modalContent: any = [];
  sections.forEach(({ controls, section }: any) => {
    const mealOptions: any = [];
    
    controls.forEach((control: any) => {
      if (control.field_type === "select") {
        data[control.uuid] &&
          mealOptions.push({
            name: control.field_name,
            value: control.options.find(
              (e: any) => e.value === data[control.uuid]
            )?.label,
          });
      } else if (control.field_type === "checkbox") {
        data[control.uuid] &&
          mealOptions.push({
            name: control.field_name,
            value: data[control.uuid] ? "Tak" : "Nie",
          });
      } else if (control.field_type === "addition") {
        data[control.uuid] > 0 &&
          mealOptions.push({
            name: control.field_name,
            value: `${data[control.uuid]}x`,
          });
      } else if (control.field_type === "additionSelect") {
        data[control.uuid]?.length > 0 &&
          mealOptions.push({
            name: control.field_name,
            value: data[control.uuid].map((e: any) => e.name).join(", "),
          });
      } else if (control.field_type === "google-maps-api") {
        data[control.uuid]?.name &&
          mealOptions.push({
            name: control.field_name,
            value: data[control.uuid].name,
          });
      } else {
        data[control.uuid] &&
          mealOptions.push({
            name: control.field_name,
            value: data[control.uuid],
          });
      }
    });
    modalContent.push({
      sectionName: section.name,
      controls: mealOptions,
    });
  });
  return modalContent;
};

export const objectEquals = (x: any, y: any) => {
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (var p in x) {
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== "object") return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!objectEquals(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  // allows x[ p ] to be set to undefined

  return true;
};
