import { Form, Input } from "antd";
import React, { useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";
import { P } from "../paragraph/Paragraph.styled";

export const TextInput: React.FC<{ control: any }> = ({ control }) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name: `${control.uuid}` });
  const methods = useFormContext();

  useEffect(() => {
    return () => {
      console.log("JZJZJZJZJ");
      methods.unregister(`${control.uuid}`);
    };
  }, []);

  return (
    <Form.Item
      label={
        <P $size="14" $height="16" $weight="400">
          {control.field_name}
        </P>
      }
      required={control.required}
      validateStatus={invalid ? "error" : undefined}
      help={invalid ? error?.message : null}
    >
      <Input
        style={{
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
        disabled={!control.writeable}
        {...field}
        pattern="^\d+(\.\d{1,2})?$"
      />
    </Form.Item>
  );
};
