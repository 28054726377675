import { Space } from "antd";
import React from "react";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { Label } from "./Labels.styled";
import CurrencyDollar from "../../../images/CurrencyDollar.svg";
import Leaf from "../../../images/Leaf.svg";
import Pepper from "../../../images/Pepper.svg";

export const Labels: React.FC = () => {
  return (
    <Space
      wrap
      style={{
        columnGap: "2px",
      }}
    >
      <Label>
        <P $size="12" $height="20" $weight="300" $family="Lato-Light">
          promocja
        </P>
        <img src={CurrencyDollar} alt="" />
      </Label>
      <Label>
        <P $size="12" $height="20" $weight="300" $family="Lato-Light">
          wege
        </P>
        <img src={Leaf} alt="" />
      </Label>
      <Label>
        <P $size="12" $height="20" $weight="300" $family="Lato-Light">
          pikantne
        </P>
        <img src={Pepper} alt="" />
      </Label>
    </Space>
  );
};
