import { Button, Flex } from "antd";
import React from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contexts/useAppContext";
import { useFormContext } from "react-hook-form";
import { saveiInStorage } from "../../../utils";
import { createMealOptionFrontView } from "../add-meal-action/AddMealAction.utils";

const GoToDeliveryButtonWrapper = styled.div`
width: 100%;
position: fixed;
bottom: 0;
left: 0;
padding-left: 20px;
padding-right: 20px;
padding-top: 20px;
padding-bottom: 20px;
border-top: 2px solid #f3f4f6;
display: flex;
justify-content: end;
background: white;
`;

const CostumAddButton = styled(Button)`
max-width: 100% !important;
width: 100% !important;
height: 48px !important;
font-size: 18px;
border-radius: 40px;
color: white;
background: #4f46e5;
border-color: #4f46e5;

&:hover {
  color: white !important;
  background: #4f46e595 !important;
  border-color: #4f46e595 !important;
}
`;

export const GoToSummary: React.FC<{ sections: any; isOrder: any }> = ({
  sections,
  isOrder,
}) => {
  const navigate = useNavigate();
  const methods = useFormContext();
  const { setDeliveryData, delivery } = useAppContext();
  const submit = () => {
    methods.handleSubmit((data: any) => {
      const frontend_data = createMealOptionFrontView(sections, data);
      setDeliveryData({
        frontend_data,
        backend_data: data,
        delivery_type: !delivery ? "DELIVERY" : "PERSONAL_COLLECTION",
      });
      saveiInStorage(data);
      navigate("/summary", { state: { from: "home", isOrder: isOrder } });
    }, console.error)();
  };

  return (
    <GoToDeliveryButtonWrapper>
      <Flex
        vertical
        justify="space-between"
        style={{
          maxWidth: "450px",
          width: "100%",
        }}
      >
        <Flex
          justify="space-between"
          gap={20}
          style={{
            maxWidth: "450px",
            width: "100%",
          }}
        >
          <CostumAddButton onClick={submit}>
            <P $size="16" $height="20" $weight="400">
              Przejdz do podsumowania
            </P>
          </CostumAddButton>
        </Flex>
      </Flex>
    </GoToDeliveryButtonWrapper>
  );
};
