import React, { useState } from "react";
import locale_pl from "dayjs/locale/pl";
import dayjs from "dayjs";
import styled from "styled-components";
import { P } from "../paragraph/Paragraph.styled";
import { useAppContext } from "../../../contexts/useAppContext";
import { useQuery } from "react-query";
import { getAvailabilityOnDate } from "../../../service";
import { useParams } from "react-router-dom";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import { Flex, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

dayjs.locale(locale_pl);


const DataWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const Data = styled.div<{ $selected: boolean }>`
  padding: 16px;
  min-width: 100px;
  border: 1px solid ${({ $selected }) => ($selected ? "#4f46e5" : "#d1d5db")};
  border-radius: 7px;
  cursor: pointer;
`;

export const TimePickerList: React.FC = () => {
  const { id } = useParams();

  const { serviceData, serviceTime, setServiceTime } = useAppContext();
  const [availabilityTime, setAvailabilityTime] = useState([])
  const { isLoading } = useQuery(
    ["/service_availability", id, serviceData],
    () => getAvailabilityOnDate({ id: id, dateToBeChecked: serviceData }),
    {
      enabled: !!serviceData,
      onSuccess(data) {
        setServiceTime(null);
        setAvailabilityTime(data)
      },
      onError() {
        setAvailabilityTime([])
      },
    }
  );

  if (isLoading)
    return (
      <Flex
        justify="center"
        style={{
          paddingTop: "30px",
        }}
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </Flex>
    );

  if (serviceData) {
    return (
      <DataWrapper>
        {availabilityTime.map((data) => {
          return (
            <Data
              $selected={serviceTime === data}
              onClick={() => setServiceTime(data)}
            >
              <P $size="14" $height="16" $weight="400">
                {data}
              </P>
            </Data>
          );
        })}
      </DataWrapper>
    );
  }
  return null;
};
