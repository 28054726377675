import { Form, Checkbox as AntCheckbox } from "antd";
import React, { useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";

export const Checkbox: React.FC<{
  control: any;
  setFinalPrice?: any;
}> = ({ control, setFinalPrice }) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name: `${control.uuid}` });
  const methonds = useFormContext();

  const value = methonds.watch(`${control.uuid}`);
  useEffect(() => {
    setFinalPrice((state: any) => {
      if (value) {
        return [
          ...state.filter((e: any) => e.id !== control.uuid),
          {
            id: control.uuid,
            value: control.price,
          },
        ];
      } else {
        return [...state.filter((e: any) => e.id !== control.uuid)];
      }
    });
  }, [value]);

  return (
    <Form.Item
      // label={control.field_name}
      required={control.required}
      validateStatus={invalid ? "error" : undefined}
      help={invalid ? error?.message : null}
    >
      <AntCheckbox {...field} checked={field.value}>
        {`${control.field_name} (+${(control.price / 100).toFixed(2)} zł)`}
      </AntCheckbox>
    </Form.Item>
  );
};
