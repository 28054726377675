import { useState, useEffect } from 'react';

const useScrollFromTop = () => {
    const [scrollTop, setScrollTop] = useState(0);

    const onScroll = () => {
        // Gets the current scroll position from the top of the page
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        setScrollTop(scrollPosition);
    };

    useEffect(() => {
        // Adds an event listener when the component mounts
        window.addEventListener('scroll', onScroll);

        // Removes the event listener when the component unmounts
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return { scrollTop };
};

export default useScrollFromTop;
