import { Form, InputNumber } from "antd";
import React from "react";
import { useController } from "react-hook-form";

export const NumberInput: React.FC<{ control: any }> = ({ control }) => {
  const pre_name =
    control.pre_name && control.pre_name !== "" ? `${control.pre_name}.` : "";
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name: `${pre_name}${control.uuid}` });

  return (
    <Form.Item
      label={control.field_name}
      required={control.required}
      validateStatus={invalid ? "error" : undefined}
      help={invalid ? error?.message : null}
    >
      <InputNumber
        style={{
          width: "100%",
          paddingTop: "4px",
          paddingBottom: "4px",
        }}
        disabled={!control.writeable}
        {...field}
      />
    </Form.Item>
  );
};
