import React from "react";
import { motion } from "framer-motion";

export const AnimadetPage: React.FC<{ children: any }> = ({ children }) => {
  const animations = {
    initial: { opacity: 0, x: "10vw" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0 },
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        justifyContent: "center",
        background: "#f3f4f6",
      }}
    >
      <motion.div
        variants={animations}
        initial="initial"
        animate="animate"
        exit="exit"
        style={{
          maxWidth: "800px",
          width: "100%",
        }}
      >
        {children}
      </motion.div>
    </div>
  );
};
