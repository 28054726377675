import React from "react";
import { CategoriesSlider } from "../../molecules/categories-slider/CategoriesSlider";
import styled from "styled-components";
import { SingleMeal } from "../../molecules/single-meal/SingleMeal";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import { useQuery } from "react-query";
import { getServices } from "../../../service";
import { motion } from "framer-motion";
import useScrollFromTop from "../../../hooks/useScrollFromTop";
import { GoToCartButton } from "../../molecules/go-to-cart-action/GoToCartButton";
import { useAppContext } from "../../../contexts/useAppContext";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { createDaysArray } from "../../../utils";

const MealsWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`;

export const CalendarPage: React.FC = () => {
  useScrollToTop();
  const { scrollTop } = useScrollFromTop();
  const { cart } = useAppContext();

  const { data = [], isFetching } = useQuery(
    ["/services", 1],
    () => getServices(1),
    { enabled: !!true }
  );

  const animations = {
    initial: { opacity: 0, x: "10vw" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0 },
  };
console.log(createDaysArray())
  if (!isFetching && data) {
    return (
      <>
        {/* <CategoriesSlider /> */}
        <AnimadetPage>
          <div
            style={{
              paddingBottom: cart.length > 0 ? "100px" : "0px",
            }}
          >
            <motion.div
              variants={animations}
              initial="initial"
              animate="animate"
              exit="exit"
              style={{
                paddingTop: scrollTop > 64 ? "35px" : "0px",
              }}
            >
              test
            </motion.div>
          </div>
        </AnimadetPage>
        {cart.length > 0 && <GoToCartButton />}
      </>
    );
  }

  return <LoadingScreen />;
};
