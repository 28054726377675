import styled from "styled-components";

export const Label = styled.div`
    display: flex;
    height: 24px;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid var(--gray-100, #F3F4F6);
    background: var(--color-grayscale-white, #FFF);
`;