import React from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { Col, Flex, Row } from "antd";
import { useAppContext } from "../../../contexts/useAppContext";

const SingleBranch = styled.div<{ $selected: boolean }>`
  max-width: 1000px;
  border-radius: 8px;
  min-height: 64px;
  cursor: pointer;
  margin-bottom: 10px;
  box-sizing: border-box;
  outline: ${({ $selected }) =>
    $selected ? "2px solid #4f46e5" : "1px solid #d1d5db"};
  background: white;
`;

const Dot = styled.div<{ $selected: boolean }>`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  right: 10px;
  border-radius: 100%;
  border: ${({ $selected }) =>
    $selected ? "4px solid #4f46e5" : "1px solid #d1d5db"};
  box-sizing: border-box;
  background: white;
`;

export const Branch: React.FC<{
  branch: any;
}> = ({ branch }) => {
  const { selectedBranch, setSelectedBranch } = useAppContext();
  const isSelected = selectedBranch && branch.id === selectedBranch.id;

  return (
    <SingleBranch
      $selected={isSelected}
      onClick={() => setSelectedBranch(branch)}
    >
      <Row>
        <Col
          span={3}
          style={{
            position: "relative",
          }}
        >
          <Dot $selected={isSelected} />
        </Col>
        <Col span={21}>
          <Flex
            align="start"
            justify="center"
            vertical
            style={{
              height: "64px",
              minHeight: "64px",
            }}
          >
            <P
              $size="14"
              $height="16"
              $weight="400"
              $family="Lato"
              style={{
                marginBottom: "5px",
              }}
            >
              {branch.name}
            </P>
            <P $size="12" $height="20" $weight="300" $family="Lato-Light">
              {branch.description ?? 'JZJZJZJ'}
            </P>
          </Flex>
        </Col>
      </Row>
    </SingleBranch>
  );
};
