import { Form, DatePicker, ConfigProvider } from "antd";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import dayjs from "dayjs";
import locale from "antd/locale/pl_PL";

const DATE_FORMAT = "YYYY-MM-DD";

export const DataPicker: React.FC<{ control: any }> = ({ control }) => {
  const pre_name =
    control.pre_name && control.pre_name !== "" ? `${control.pre_name}.` : "";
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name: `${pre_name}${control.uuid}` });
  const methods = useFormContext();

  return (
    <Form.Item
      label={control.field_name}
      required={control.required}
      validateStatus={invalid ? "error" : undefined}
      help={invalid ? error?.message : null}
    >
      <ConfigProvider locale={locale}>
        <DatePicker
          disabled={!control.writeable}
          allowClear={false}
          style={{
            width: "100%",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          {...field}
          value={field.value && dayjs(field.value, DATE_FORMAT)}
          onChange={(_, value) =>
            methods.setValue(`${pre_name}${control.uuid}`, value)
          }
          format={DATE_FORMAT}
        />
      </ConfigProvider>
    </Form.Item>
  );
};
