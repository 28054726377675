import { QueryClient } from 'react-query'
export * from './client'

export const reactQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0
        }
    }
})