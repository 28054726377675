import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useQuery, useQueryClient } from "react-query";
import useScrollFromTop from "../../../hooks/useScrollFromTop";
import { scrollToElementById } from "../../../utils";
import { LeftOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { getServices, getServicesCategory } from "../../../service";
import { useAppContext } from "../../../contexts/useAppContext";

const ScrollableFixedBar = styled.div<{ $fixed: any }>`
  position: ${({ $fixed }) =>
    $fixed ? "fixed" : "relative"}; /* dynamic border color */
  z-index: 1000;
  top: 0; /* Adjust as needed */
  left: 0;
  width: 100%;
  overflow-x: auto;
  background-color: #fff !important;
  white-space: nowrap;
  scrollbar-width: none;
  border-bottom: 2px solid #f3f4f6;
`;

const ScrollableContent = styled.div`
  display: flex;
  align-items: center;
  scrollbar-width: none;
  position: relative;
`;

const Item = styled.div<{ $firstElement: boolean; $selected: boolean }>`
  padding-left: 15px;
  padding-right: 15px; /* top and bottom padding 4px, left and right padding 10px */
  display: inline-block;
  text-transform: uppercase;
  background-color: #fff; /* Your preferred item background color */
  /* Add more styling as needed */
  height: 48px;
  cursor: pointer;
  display: flex; /* use flexbox for layout */
  justify-content: center; /* center content horizontally */
  align-items: center; /* center content vertically */
  text-align: center;
  position: relative;

  &:after {
    content: "";
    height: 2px;
    width: 100%;
    background: ${({ $selected }) => ($selected ? "#000" : "#fff")};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const ScrollRight = styled.div<{ $fixed: any }>`
  position: ${({ $fixed }) =>
    $fixed ? "fixed" : "absolute"}; /* dynamic border color */
  top: 0;
  right: 0;
  width: 40px;
  height: 48px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 15%,
    rgba(255, 255, 255, 0.9) 100%
  );
  z-index: 2000;
  display: flex;
  justify-content: end;
  align-items: center;
`;

const ScrollLeft = styled.div<{ $fixed: any }>`
  position: ${({ $fixed }) =>
    $fixed ? "fixed" : "absolute"}; /* dynamic border color */
  top: 0;
  left: 0;
  width: 40px;
  height: 48px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 15%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 2000;
  display: flex;
  justify-content: start;
  align-items: center;
`;
const Paragraph = styled(P)<{}>`
  position: relative;
`;

export const ServicesCategoriesSlider: React.FC = () => {
  const divRef = useRef(null);
  const { selectedCategory, setSelectedCategory } = useAppContext();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isAtMaxRight, setIsAtMaxRight] = useState(false);
  const [isAtMaxLeft, setIsAtMaxLeft] = useState(true);

  useEffect(() => {
    const div = divRef.current;
    if (div) {
      const { scrollHeight, clientHeight, scrollWidth, clientWidth } = div;
      setIsOverflowing(
        scrollHeight > clientHeight || scrollWidth > clientWidth
      );
    }
  }, []);

  useEffect(() => {
    const scrollableElement: any = divRef.current;
    if (scrollableElement) {
      const handleScroll = () => {
        // Check if the scroll position is at the bottom
        const isBottom =
          scrollableElement.scrollLeft === scrollableElement.scrollLeftMax;

        setIsAtMaxRight(isBottom);
      };

      // Attach scroll event listener to the scrollable element
      scrollableElement.addEventListener("scroll", handleScroll);

      return () => {
        // Clean up the event listener when the component unmounts
        scrollableElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const scrollableElement: any = divRef.current;
    if (scrollableElement) {
      const handleScroll = () => {
        // Check if the scroll position is at the bottom
        const isBottom = scrollableElement.scrollLeft === 0;

        setIsAtMaxLeft(isBottom);
      };

      // Attach scroll event listener to the scrollable element
      scrollableElement.addEventListener("scroll", handleScroll);

      return () => {
        // Clean up the event listener when the component unmounts
        scrollableElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const { data: categories = [], isFetching } = useQuery(
    ["/services-categories"],
    getServicesCategory,
    { enabled: !!true,
    onSuccess(data) {
      setSelectedCategory(data?.[0])
    }, }
  );  
  
  const { scrollTop } = useScrollFromTop();

  const scrollLeft = () => {
    if (divRef.current)
      (divRef.current as any)?.scrollBy({ left: -300, behavior: "smooth" });
  };

  const scrollRight = () => {
    if (divRef.current)
      (divRef.current as any)?.scrollBy({ left: 300, behavior: "smooth" });
  };

  if (isFetching) return null

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {isOverflowing && !isAtMaxRight && (
        <ScrollRight $fixed={scrollTop > 64}>
          <Button
            onClick={scrollRight}
            style={{
              background: "none",
              border: "none",
              boxShadow: "none",
            }}
            type="primary"
            shape="circle"
            icon={
              <RightOutlined
                style={{
                  color: "black",
                }}
              />
            }
          />
        </ScrollRight>
      )}
      {isOverflowing && !isAtMaxLeft && (
        <ScrollLeft $fixed={scrollTop > 64}>
          <Button
            onClick={scrollLeft}
            style={{
              background: "none",
              border: "none",
              boxShadow: "none",
            }}
            type="primary"
            shape="circle"
            icon={
              <LeftOutlined
                style={{
                  color: "black",
                }}
              />
            }
          />
        </ScrollLeft>
      )}
      <ScrollableFixedBar $fixed={scrollTop > 64} ref={divRef}>
        <ScrollableContent>
          {categories.map((element: any, index: number) => {
            return (
              <Item
                $selected={selectedCategory?.id === element?.id}
                key={element.id}
                $firstElement={0 === index}
                onClick={() => {
                  setSelectedCategory(element);
                  scrollToElementById(element.id);
                }}
              >
                <Paragraph $size="14" $height="16" $weight="400">
                  {element.name}
                </Paragraph>
              </Item>
            );
          })}
        </ScrollableContent>
      </ScrollableFixedBar>
    </div>
  );
};
