import React, { useState } from "react";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import styled from "styled-components";
import { useQuery } from "react-query";
import { getBranches } from "../../../service";
import { useNavigate, useParams } from "react-router-dom";
import { GoToStatute } from "../../molecules/go-to-statute/GoToStatute";
import { BranchHeader } from "../../organisms/branch-header/BranchHeader";
import { Branch } from "../../atoms/branch/Branch";
import { useAppContext } from "../../../contexts/useAppContext";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";

const DetailsWrapper = styled.div`
  padding-top: 230px;
  padding-bottom: 220px;
  padding-left: 20px;
  padding-right: 20px;
  background: #f3f4f6;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const BranchesPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setSelectedBranch } = useAppContext();
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);

  const { isFetching } = useQuery(["/branches", id], getBranches, {
    onSuccess(data) {
      if (data.length > 1) {
        setBranches(data);
      } else if (data.length === 1) {
        setSelectedBranch(data[0]);
        navigate("/statute", { state: { from: "home" } });
        return;
      }
      setLoading(false);
    },
  });

  if (!isFetching && !loading) {
    return (
      <>
        <BranchHeader />
        <AnimadetPage>
          <DetailsWrapper>
            {branches.map((branch: any) => {
              return <Branch branch={branch} />;
            })}
          </DetailsWrapper>
        </AnimadetPage>
        <GoToStatute />
      </>
    );
  }

  return <LoadingScreen />;
};
