import { Form, Button, Flex, ConfigProvider, DatePicker } from "antd";
import React, { useEffect, useState } from "react";

import { P } from "../paragraph/Paragraph.styled";
import { useLocation } from "react-router-dom";
import plPL from "antd/es/locale/pl_PL";
import locale from "antd/es/date-picker/locale/pl_PL";
import { useController, useFormContext } from "react-hook-form";

export const SetDeliveryTime: React.FC<{
  control: any;
}> = ({ control }) => {
  const {
    fieldState: { invalid, error },
  } = useController({ name: `${control.uuid}` });
  const methonds = useFormContext();

  // const location = useLocation();
  // const isOrder = !!location?.state?.isOrder;

  const [deliveryNow, setDeliveryNow] = useState(true);

  useEffect(() => {
    if (deliveryNow) {
      methonds.setValue(control.uuid, "Jak najszybciej");
    }
  }, [deliveryNow]);

  return (
    <Form.Item
      label={control.field_name}
      required={!!control?.required}
      validateStatus={invalid ? "error" : undefined}
      help={invalid ? error?.message : null}
    >
      <Flex justify="space-between" align="center">
        {deliveryNow ? (
          <P
            $size="14"
            $weight="300"
            $height="20"
            $family="Lato-Light"
            style={{
              paddingTop: "3px",
            }}
          >
            Jak najszybciej
          </P>
        ) : (
          <Flex justify="space-between" align="center" gap={4}>
            <ConfigProvider locale={plPL}>
              <DatePicker
                showTime
                locale={locale}
                placeholder="Data"
                format={"DD-MM-YYYY HH:mm"}
                onChange={(_, e1) => {
                  methonds.setValue(control.uuid, e1);
                }}
              />
            </ConfigProvider>
          </Flex>
        )}

        <Button
          type="link"
          style={{
            paddingRight: 0,
            color: "#4F46E5",
          }}
          onClick={() => {
            setDeliveryNow((now) => !now);
          }}
        >
          <P $size="16" $height="20" $weight="400">
            {deliveryNow ? "Zaplanuj" : "Najszybciej "}
          </P>
        </Button>
      </Flex>
    </Form.Item>
  );
};
