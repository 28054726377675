import { createContext } from "react";

interface AppContextProps {
  cart: any;
  setCart: any;
  mealName: any;
  setMealName: any;
  deliveryPrice: any;
  setDeliveryPrice: any;
  selectedBranch: any;
  setSelectedBranch: any;
  delivery: any;
  setDelivery: any;
  deliveryData: any;
  setDeliveryData: any;
  serviceData: any;
  setServiceData: any;
  serviceTime: any;
  setServiceTime: any;
  selectedCategory:any;
  setSelectedCategory:any;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);
