import { ReactNode, useState } from "react";
import { AppContext } from "./AppContext";
import React from "react";
export const AppContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  // branch restauracji
  const [selectedBranch, setSelectedBranch] = useState(null);
  // dane potrzebne do skompletowania zamówienia
  const [cart, setCart] = useState<any>([]);
  const [deliveryData, setDeliveryData] = useState(null);
  const [delivery, setDelivery] = useState('TO_THE_ADDRESS'); 
  const [deliveryPrice, setDeliveryPrice] = useState<any>(0);
  const [serviceData, setServiceData] = useState<any>(null);
  const [serviceTime, setServiceTime] = useState<any>(null);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);

  const [mealName, setMealName] = useState<any>("");
  return (
    <AppContext.Provider
      value={{
        cart,
        setCart,
        mealName,
        setMealName,
        deliveryPrice,
        setDeliveryPrice,
        selectedBranch,
        setSelectedBranch,
        delivery,
        setDelivery,
        deliveryData,
        setDeliveryData,
        serviceData,
        setServiceData,
        serviceTime,
        setServiceTime,
        selectedCategory,
        setSelectedCategory
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
