import Joi from "joi";

export const createShema = (order: any, bypass?: boolean) => {
  if (!order) return;
  let objextToreturn: any = {};

  order.forEach((element: any) => {
    if (element.field_type !== "comments") {
      if (element.field_type === "input-number") {
        objextToreturn[element.uuid] = element.required
          ? Joi.number().required().messages({
              "number.base": "Pole musi być liczbą",
              "any.required": "Pole musi być liczbą",
            })
          : Joi.any();
      } else {
        objextToreturn[element.uuid] = element.required
          ? Joi.any()
              .empty("")
              .required()
              .messages({ "any.required": "Pole jest wymagane" })
          : Joi.any();
      }
    } else {
      objextToreturn[element.uuid] = Joi.any();
    }
  });
  console.log("objextToreturn", objextToreturn);
  return Joi.object(objextToreturn);
};

export const joiResolver = (schema: any) => {
  return (data: any) => {
    const { error, value } = schema.validate(data, { abortEarly: false });

    return {
      values: error ? {} : value,
      errors: error
        ? error.details.reduce((previous: any, currentError: any) => {
            return {
              ...previous,
              [currentError.path.join(".")]: {
                type: currentError.type,
                message: currentError.message,
              },
            };
          }, {})
        : {},
    };
  };
};

export const createFormInitialValue = (order: any) => {
  if (!order) return;
  let objextToreturn: any = {};
  order.forEach((element: any) => {
    if (element.field_type === "addition") {
      objextToreturn[`${element.uuid}`] = element.value ?? 0;
    }
    if (element.field_type === "additionSelect") {
      objextToreturn[`${element.uuid}`] = [];
    } else {
      if (element.field_type !== "google-maps-api") {
        objextToreturn[`${element.uuid}`] =
          element?.getFromStorage && localStorage.getItem(element.uuid)
            ? localStorage.getItem(element.uuid)
            : element.value ?? undefined;
      } else {
        objextToreturn[`${element.uuid}`] = undefined;
      }
    }
  });
  return objextToreturn;
};

export const saveiInStorage = (data: any) => {
  if (!data) return;
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      if (typeof data[key] === "object" && data[key]?.place_id) {
        localStorage.setItem(key, JSON.stringify(data[key].place_id));
      } else localStorage.setItem(key, data[key]);
    }
  });
};

export const scrollToElementById = (id: any) => {
  const element = document.getElementById(id);
  if (element) {
    const elementPosition =
      element.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - 35;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

export const createDaysArray = () => {
  const daysOfWeek = [
    "niedziela",
    "poniedziałek",
    "wtorek",
    "środa",
    "czwartek",
    "piątek",
    "sobota",
  ];
  const monthsShort = [
    "Sty",
    "Lut",
    "Mar",
    "Kwi",
    "Maj",
    "Cze",
    "Lip",
    "Sie",
    "Wrz",
    "Paź",
    "Lis",
    "Gru",
  ];
  const result = [];

  for (let i = 0; i < 90; i++) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + i);

    let dayName = daysOfWeek[currentDate.getDay()];
    if (i === 0) {
      dayName = "dziś";
    } else if (i === 1) {
      dayName = "jutro";
    }

    const shortDateName = `${currentDate.getDate()} ${
      monthsShort[currentDate.getMonth()]
    }`;

    result.push({
      date: currentDate.toLocaleDateString("pl-PL"),
      dayName: dayName,
      shortDateName: shortDateName,
    });
  }

  return result;
};
