import React, { useEffect, useState } from "react";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import styled from "styled-components";
import test from "../../../images/meal.jpg";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { Labels } from "../../molecules/labels/Labels";
import { SupportedControls } from "../../molecules/supported-controls/SupportedControls";
import { Form } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { getDetails, getProductDetails } from "../../../service";
import { useParams } from "react-router-dom";
import { AdditionsHeader } from "../../atoms/additions-header/AdditionsHeader";
import { AddMealAction } from "../../molecules/add-meal-action/AddMealAction";
import {
  createFormInitialValue,
  createShema,
  joiResolver,
} from "../../../utils";
import { useAppContext } from "../../../contexts/useAppContext";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import useScrollToTop from "../../../hooks/useScrollToTop";

const Image = styled.div`
  display: flex;
  height: 150px;
  padding: 8px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: var(--space-space-4, 16px);
  background: url(${test}), lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  margin-bottom: 12px;
`;

const DetailsWrapper = styled.div`
  padding-top: 24px;
  background: white;
`;

export const ProductDetails: React.FC = () => {
  useScrollToTop();
  const { id } = useParams();
  const { setMealName } = useAppContext();
  const [finalPrice, setFinalPrice] = useState<any>([]);
  const [controls, setControls] = useState<any>([]);
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState([]);
  const [additionals, setAdditionals] = useState([]);
  const [extentions, setExtentions] = useState([]);

  const { isFetching } = useQuery(
    ["/product-details", id],
    () => getProductDetails({ id }),
    {
      enabled: !!id,
      onSuccess(data) {
        const options = data.options ?? [];
        // const additionals1 = data.additionals;
        // const additionals =
        //   data.additionals.map((addition: any) => {
        //     return addition.controls.map((control: any) => {
        //       return {
        //         ...control,
        //         isAddition: true,
        //         category_name: addition.name,
        //       };
        //     });
        //   }) ?? [];
        // const controls = [...options, ...additionals.flat()];
        setMealName(data.name);
        // setAdditionals(additionals1);
        setOptions(options);
        setDescription(data.description);
        setExtentions(data.productExtensionCategory ?? []);
        const additionals =
          data.productExtensionCategory.map((extentionCategory: any) => {
            if (extentionCategory.type === "QUANTITIES") {
              return extentionCategory.extensions.map((extension: any) => {
                return {
                  field_type: "addition",
                  uuid: extension.id,
                  field_name: extension.name,
                  price: extension.priceExtension,
                  getFromStorage: false,
                  isAddition: true,
                  category_name: extentionCategory.name,
                };
              });
            } else {
              return [
                {
                  field_type: "additionSelect",
                  uuid: `additionSelect-${extentionCategory.id}`,
                  field_name: extentionCategory.name,
                  getFromStorage: false,
                  isAddition: true,
                },
              ];
            }
          }) ?? [];
        const controls = [...options, ...additionals.flat()];
        console.log("controls", controls);
        setControls(controls);
        setFinalPrice([
          {
            id: "BASE_PRICE",
            value: data.basePrice,
          },
        ]);
      },
    }
  );

  const schema = createShema(controls);
  const methods = useForm({
    resolver: joiResolver(schema),
  });

  useEffect(() => {
    if (controls.length > 0) {
      methods.reset(createFormInitialValue(controls));
    }
    return;
  }, [controls]);

  useEffect(() => {
    return () => {
      setMealName("");
    };
  }, []);

  if (!isFetching) {
    return (
      <>
        <FormProvider {...methods}>
          <AnimadetPage>
            <DetailsWrapper>
              <div
                style={{
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
              >
                <Image />
                <P
                  $height="20"
                  $size="14"
                  $weight="300"
                  $family="Lato-Light"
                  style={{
                    marginBottom: "12px",
                  }}
                >
                  {description}
                </P>
                <Labels />
              </div>
              <div
                style={{
                  marginTop: "30px",
                }}
              >
                <Form layout="vertical">
                  {extentions &&
                    extentions.map((extentionCategory: any) => {
                      return (
                        <>
                          <AdditionsHeader
                            key={extentionCategory.id}
                            text={extentionCategory.name}
                          />
                          <div
                            style={{
                              paddingLeft: "12px",
                              paddingRight: "12px",
                              paddingTop: "12px",
                              paddingBottom: "1px",
                            }}
                          >
                            {extentionCategory.type === "QUANTITIES" ? (
                              extentionCategory.extensions.map(
                                (control: any, index: number) => {
                                  return (
                                    <SupportedControls
                                      control={{
                                        field_type: "addition",
                                        uuid: control.id,
                                        field_name: control.name,
                                        price: control.priceExtension,
                                        getFromStorage: false,
                                        minimumRequiredQuantity:
                                          control.minimumRequiredQuantity,
                                        maximumRequiredQuantity:
                                          control.maximumRequiredQuantity,
                                      }}
                                      key={control.id}
                                      finalPrice={finalPrice}
                                      setFinalPrice={setFinalPrice}
                                    />
                                  );
                                }
                              )
                            ) : (
                              <SupportedControls
                                control={{
                                  field_type: "additionSelect",
                                  uuid: `additionSelect-${extentionCategory.id}`,
                                  field_name: extentionCategory.name,
                                  minimumRequiredElements:
                                    extentionCategory.minimumRequiredElements,
                                  maximumRequiredElements:
                                    extentionCategory.maximumRequiredElements,
                                  extensions: extentionCategory.extensions,
                                  type: extentionCategory.type,
                                }}
                                finalPrice={finalPrice}
                                setFinalPrice={setFinalPrice}
                              />
                            )}
                          </div>
                        </>
                      );
                    })}
                </Form>
              </div>
            </DetailsWrapper>
          </AnimadetPage>
          <AddMealAction finalPrice={finalPrice} controls={controls} />
        </FormProvider>
      </>
    );
  }

  return <LoadingScreen />;
};
